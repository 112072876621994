//
//
//
//
//
//
export default {
  name: 'App',
  mounted: function mounted() {
    // this.$q.loading.show({})
    // console.log('App.vue: onMounted ')
    if (this.$q.platform.is.cordova) {
      // document.addEventListener('deviceready', this.onDeviceReady, false)
      // document.addEventListener('offline', this.onOffline, false)
      // document.addEventListener('online', this.onOnline, false)
      // document.addEventListener('pause', this.onPause, false)
      document.addEventListener('resume', this.onResume, false); // document.addEventListener('menubutton', this.onMenuKeyDown, false)
      // document.addEventListener('backbutton', this.onBackKeyDown, false)
    } // throw new Error('Sentry Error MVD')

  },
  methods: {
    onResume: function onResume() {
      // console.log('App.vue: cordova:onResume ')
      this.$meteor.reconnect();
      this.$store.dispatch('content/loadContent');
    }
  }
};