import isMeteorUser from "../boot/meteor/isMeteorUser.js";
var routes = [// {
//     path: '/',
//     component: () => import('pages/Index.vue')
// },
{
  path: '/',
  component: function component() {
    return import('layouts/MyLayout.vue');
  },
  children: [{
    path: '',
    props: true,
    component: function component() {
      return import('pages/Timeline.vue');
    },
    beforeEnter: function beforeEnter(to, from, next) {
      isMeteorUser().then(function (response) {
        // if true, continue, else redirect to Login page
        response ? next() : next({
          name: 'welcome'
        });
      });
    }
  }, {
    path: 'welcome',
    name: 'welcome',
    meta: {
      showToolbar: false
    },
    component: function component() {
      return import('pages/Welcome.vue');
    }
  }, {
    path: 'timeline',
    name: 'timeline',
    props: true,
    component: function component() {
      return import('pages/Timeline.vue');
    }
  }, {
    path: 'measureDetail',
    name: 'measureDetail',
    props: true,
    component: function component() {
      return import('pages/Timeline.vue');
    }
  }, {
    path: 'my-areas',
    name: 'myAreas',
    component: function component() {
      return import('pages/MyAeraPage.vue');
    }
  }, {
    path: 'map',
    name: 'map',
    component: function component() {
      return import('pages/Map.vue');
    },
    props: true
  },
  /* { path: 'create-area', name: 'createMaatregel', component: () => import('pages/CreateAreaPage.vue') }, */
  {
    path: 'area/:id/edit',
    name: 'editArea',
    component: function component() {
      return import('pages/EditAreaPage.vue');
    }
  }, {
    path: 'area/:id',
    name: 'area',
    component: function component() {
      return import('pages/AreaPage.vue');
    }
  }, {
    path: 'add-area-info',
    name: 'addAreaInfoPage',
    component: function component() {
      return import('pages/AddAreaInfoPage.vue');
    }
  }, // { path: 'introduction', name: 'introduction', component: () => import('pages/Introduction.vue') },
  {
    path: 'account',
    name: 'account',
    component: function component() {
      return import('pages/Account.vue');
    }
  }, {
    path: 'login',
    component: function component() {
      return import('pages/Login.vue');
    }
  }, {
    path: 'info',
    name: 'info',
    component: function component() {
      return import('pages/Info.vue');
    }
  } // { path: 'profile', component: () => import('pages/Profile.vue') },
  // { path: 'settings', component: () => import('pages/Settings.vue') },
  ]
}, {
  path: '/survey',
  component: function component() {
    return import('layouts/SurveyLayout.vue');
  },
  children: [{
    path: 'introduction',
    name: 'introduction',
    component: function component() {
      return import('pages/Introduction.vue');
    }
  }, {
    path: 'home',
    name: 'setHome',
    component: function component() {
      return import('pages/SetHome.vue');
    }
  }, {
    path: 'create-area',
    name: 'createMaatregel',
    component: function component() {
      return import('pages/CreateAreaPage.vue');
    }
  }]
}]; // Always leave this as last one

if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: function component() {
      return import('pages/Error404.vue');
    }
  });
}

export default routes;