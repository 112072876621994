import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import _typeof from "D:\\projects\\xena\\node_modules\\@babel\\runtime-corejs2/helpers/typeof";
import "core-js/modules/es6.function.name";
import _defineProperty from "D:\\projects\\xena\\node_modules\\@babel\\runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.object.assign";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Meteor } from 'meteor/meteor';
import { Mongo } from 'meteor/mongo';
import { check } from 'meteor/check';
import { Accounts } from 'meteor/accounts-base'; // import { getMeazure } from './my-functions'
// import introductie from '../assets/introductie.json'
// var Users = new Mongo.Collection('users', {idGeneration: 'MONGO'})

export var Tasks = new Mongo.Collection('tasks');
export var Measures = new Mongo.Collection('measures', {
  idGeneration: 'MONGO'
});
export var Gebied = new Mongo.Collection('gebied', {
  idGeneration: 'MONGO'
});
export var Notices = new Mongo.Collection('notices');
export var Adverts = new Mongo.Collection('adverts');
export var Images = new Mongo.Collection('images');
export var Polygons = new Mongo.Collection('polygons');
export var introductieContentName = 'introductie';
export var locationContentName = 'location'; // export const contentNames = [introductieContentName, locationContentName]
// const content = []
// content[introductieContentName] = introductie

import { HTTP } from 'meteor/http'; // import { WebApp } from 'meteor/webapp'

if (Meteor.isServer) {
  var Extensions = require('websocket-extensions'),
      deflate = require('permessage-deflate'),
      zlib = require('zlib');

  deflate = deflate.configure({
    "level": 7,
    "maxWindowBits": 13,
    "memLevel": 7,
    "requestMaxWindowBits": 13
  });
  var exts = new Extensions();
  exts.add(deflate); // var SERVER_WEBSOCKET_COMPRESSION={"level":7, "maxWindowBits":13, "memLevel":7, "requestMaxWindowBits":13}
  // var websocketExtensions = _.once(function () {
  //     console.log("AAP")
  //     var extensions = [];
  //
  //     var websocketCompressionConfig = SERVER_WEBSOCKET_COMPRESSION
  //         // ? JSON.parse(process.env.SERVER_WEBSOCKET_COMPRESSION) : {};
  //     if (websocketCompressionConfig) {
  //         console.log("AAP3")
  //         extensions.push(Npm.require('permessage-deflate').configure(
  //             websocketCompressionConfig
  //         ));
  //     }
  //
  //     return extensions;
  // });

  WebApp.rawConnectHandlers.use(function (req, res, next) {
    res.setHeader('Access-Control-Allow-Origin', '*');
    res.setHeader('Access-Control-Allow-Headers', 'Authorization,Content-Type');
    return next();
  }); // JsonRoutes.setResponseHeaders({
  //     "Cache-Control": "no-store",
  //     "Pragma": "no-cache",
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
  //     "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
  // })

  Router.route('/test', {
    where: 'server'
  }).get(function () {
    var response = Meteor.call('_test');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  Accounts.onCreateUser(function (options, user) {
    // console.log('----------------------Accounts.onCreateUser --------------------------------')
    var userToCreate = Object.assign({
      createdAt: new Date()
    }, user);
    if (options.profile) userToCreate.profile = options.profile;
    return userToCreate;
  }); // Accounts.onUpdateUser((options, user) => {
  //     // console.log('----------------------Accounts.onCreateUser --------------------------------')
  //     const userToCreate = Object.assign({
  //         createdAt: new Date()
  //     }, user)
  //
  //     if (options.profile) userToCreate.profile = options.profile
  //
  //     return userToCreate
  // })
  // Router.route('/content/:file', { where: 'server' }).get(function () {
  //     console.log('params.file', this.params.file)
  //     let contentFile = content[this.params.file]
  //     console.log('contentFile EXISTS: ', contentFile !== undefined)
  //     // var response = Images.find({
  //     //     userid: this.params.userid
  //     // }).fetch();
  //     // //
  //     this.response.setHeader('Content-Type', 'application/json')
  //     // this.response.end(introductie)
  //     this.response.end(JSON.stringify(contentFile))
  // })
  // SSL( Assets.getText('C:\\playground\\mybetaquasar\\api\\localhost.key'), Assets.getText('C:\\playground\\mybetaquasar\\api\\localhost.cert'), 443)

  /*  SSL(
      'assets/app/localhost.key',
      'assets/app/localhost.cert',
      443)
   SSL(
      'assets/app/localhost.key',
      'assets/app/localhost.cert',
      4000)
  */
  // Listen to incoming HTTP requests (can only be used on the server).
  // WebApp.connectHandlers.use('*', (req, res, next) => {
  //     res.writeHead(205)
  //     res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
  //     res.end(`Hello world from: ${Meteor.release}`)
  // })

  /*  Meteor.startup(function () {
      console.log('----------settting request headers--------------')
      WebApp.rawConnectHandlers.use(function (req, res, next) {
          res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
          res.setHeader('Access-Control-Allow-Origin', '*')
          res.setHeader('Access-Control-Allow-Methods', 'POST')
          res.writeHead(200)
          res.end()
      })
      WebApp.connectHandlers.use(function (req, res, next) {
          // add allow origin
          res.setHeader('Access-Control-Allow-Origin', '*')
          res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
           res.setHeader('Access-Control-Allow-Headers', [
              'Accept',
              'Accept-Charset',
              'Accept-Encoding',
              'Accept-Language',
              'Accept-Datetime',
              'Authorization',
              'Cache-Control',
              'Connection',
              'Cookie',
              'Content-Length',
              'Content-MD5',
              'Content-Type',
              'Date',
              'User-Agent',
              'X-Requested-With',
              'Origin'
          ].join(', '))
      })
  })  */
  // Meteor.publish('directory', function () {
  //     var currentUser
  //     currentUser = this.userId
  //     // return Meteor.users.find({}, { fields: { emails: 1, profile: 1 } })
  //     return Meteor.users.find({ _id: currentUser }, { fields: { emails: 1, profile: 1 } })
  // })

  Meteor.publish('tasks', function tasksPublication() {
    return Tasks.find();
  });
  Meteor.publish('polygons', function polygonsPublication() {
    return Polygons.find();
  });
  Meteor.publish('measures', function measuresPublication() {
    this.unblock();
    return Measures.find({}, {
      sort: {
        dateCreated: -1
      },
      fields: {// 'id': 1,
        // 'type': 1,
        // 'geometry': 1,
      } // limit:

    });
  });
  /*Meteor.publish('aeraFriends', function measuresPublication () {
      this.unblock();
      return Measures.find({}, {
          sort: {dateCreated: -1},
          fields: {
              // 'id': 1,
              // 'type': 1,
              // 'geometry': 1,
          },
          // limit:
      })
  })*/
  // Meteor.publish('measures', function measuresPublication () {
  //     return Measures.find()
  // })

  Meteor.publish('notices', function noticesPublication() {
    return Notices.find();
  });
  Meteor.publish('gebied', function gebiedPublication() {
    return Gebied.find();
  });
  Meteor.publish('adverts', function advertsPublication() {
    return Adverts.find();
  });
  Meteor.startup(function () {
    Measures._ensureIndex({
      dateCreated: -1
    });
  });
  Meteor.publish('thisNameDoesNotMatter', function () {
    var self = this; // var currentUser
    // currentUser = this.userId
    // var handle = Meteor.users.find({ _id: currentUser, 'profile.city': 'Groningen' }, {
    // var handle = Meteor.users.find({ _id: currentUser }, {

    var handle = Meteor.users.find({}, {
      fields: {
        // emails: 1,
        'profile.name': 1,
        'profile.medals': 1,
        'profile.score': 1,
        'profile.city': 1
      }
    }).observeChanges({
      added: function added(id, fields) {
        self.added('userData', id, fields);
      },
      changed: function changed(id, fields) {
        self.changed('userData', id, fields);
      },
      removed: function removed(id) {
        self.removed('userData', id);
      }
    });
    self.ready();
    self.onStop(function () {
      handle.stop();
    });
  }); // Meteor.publish('userdata', function measuresPublication () {
  //     return Meteor.users.find()
  // })
  // Meteor.publish('userdata2', function () {
  //     var currentUser
  //     currentUser = Meteor.userId()
  //     if (currentUser) {
  //         return Meteor.users.find({
  //             _id: currentUser
  //         }, {
  //             fields: {
  //                 'emails': 1
  //             }
  //         })
  //     } else {
  //         return this.ready()
  //     }
  // })
}

Meteor.methods({
  // createUUID: function ()  {
  //     // http://www.ietf.org/rfc/rfc4122.txt
  //     var s = [];
  //     var hexDigits = "0123456789abcdef";
  //     for (var i = 0; i < 36; i++) {
  //         s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  //     }
  //     s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  //     s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  //     s[8] = s[13] = s[18] = s[23] = "-";
  //
  //     var uuid = s.join("");
  //     return uuid;
  // },
  _test: function _test() {
    console.log('\x1b[32m%s\x1b[0m', 'TEST method called');
    return {
      status: 200,
      message: "this is a server response :)"
    };
  },
  'tasks.insert': function tasksInsert(title) {
    check(title, String);
    Tasks.insert({
      title: title,
      createdAt: new Date()
    });
  },
  'tasks.update': function tasksUpdate(taskId, task) {
    check(taskId, String);
    check(task, String);
    Tasks.update(taskId, {
      $set: {
        _id: taskId,
        updatedAt: new Date(),
        title: task
      }
    });
  },
  'tasks.remove': function tasksRemove(taskId) {
    check(taskId, String);
    Tasks.remove(taskId);
  },
  'polygon.insert': function polygonInsert(polygon) {
    /* console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+measure.properties.userid+']'); */
    console.log('\x1b[32m%s\x1b[0m', 'Polygon insert');
    polygon.dateCreated = new Date();
    polygon.createdBy = Meteor.userId();
    var id = Polygons.insert(polygon);
    console.log('\x1b[32m%s\x1b[0m', 'CREATED Polygon ' + id); // console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+marker.properties.userid+']');

    /* return {
      id      : id,
      status  : 201,
      message : "Marker:["+id+"] is successfully created"
    }; */
  },
  'measure.insert': function measureInsert(measure, data, analitics) {
    /* console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+measure.properties.userid+']'); */
    console.log('\x1b[32m%s\x1b[0m', 'CALL: measure.insert START'); // console.log('measure', measure)
    // console.log('data', data)
    // console.log('user', Meteor.userId() + Meteor.user().emails)
    // console.log('email', Meteor.user().emails)

    delete measure['properties'];

    var area = _objectSpread({}, measure, {}, data, {}, analitics);

    console.log('area', area);
    area.dateCreated = data.dateCreated ? data.dateCreated : new Date();
    area.createdBy = data.createdBy ? data.createdBy : Meteor.userId();
    var s = [];
    var hexDigits = "0123456789abcdef";

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    area.id = uuid; // area.analitics = analitics
    // Meteor._sleepForMs(3000)

    var _id = Measures.insert(area);

    if (area.createdBy === Meteor.userId()) {
      Meteor.users.update({
        _id: Meteor.userId()
      }, {
        $inc: {
          'profile.score': 1
        }
      });
    } // Meteor._sleepForMs(1000)
    // let measures = Measures.find({ 'properties.createdBy': Meteor.userId() }, { sort: { dateCreated: -1 } }).fetch()


    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
    console.log('\x1b[32m%s\x1b[0m', 'CREATED Measure ' + _id);
    console.log('\x1b[32m%s\x1b[0m', 'CALL: measure.insert END');
  },
  'measure.delete': function measureDelete(measureId) {
    console.log('CALL: measure.delete START', measureId);
    var measure = Measures.findOne({
      '_id': measureId
    });
    console.log('measureId', measureId);
    console.log('measure', measure);
    console.log('user', Meteor.userId() + Meteor.user().emails);
    console.log('email', Meteor.user().emails);

    if (measure.createdBy === Meteor.userId()) {
      Measures.remove(measureId);
      Meteor.users.update({
        _id: Meteor.userId()
      }, {
        // $set: {
        //     'profile.score': Meteor.user().profile.score ? Meteor.user().profile.score > 0 ? Meteor.user().profile.score-- : 0 : 0
        // }
        $inc: {
          'profile.score': -1
        }
      });
    } else {
      throw new Meteor.Error('not allowed', 'Cannot delete measure');
    }

    console.log('measure.delete END', measureId);
  },
  'measure.update': function measureUpdate(measureId, data) {
    console.log('CALL: measure.update START', measureId); // console.log('measureId', measureId)

    console.log('data', data);
    console.log('data...', data); // console.log('user', Meteor.userId() + Meteor.user().emails)
    // console.log('email', Meteor.user().emails)

    var measure = Measures.findOne({
      '_id': measureId
    }); // console.log('measure', measure)
    // check(title, String)
    // check(description, String)

    if (measure.createdBy === Meteor.userId()) {
      console.log('-----------ok---------------');
      var result = Measures.update(measureId, {
        $set: _objectSpread({
          updatedAt: new Date()
        }, data)
      });
      console.log('-----------result-----------', result);
    }

    measure = Measures.findOne({
      '_id': measureId
    });
    console.log('measure UPDATED', measure);
    console.log('measure.update END', measureId);
  },
  // 'measure.update' (measureId, title, description) {
  //     console.log('CALL: measure.update START', measureId)
  //     console.log('measureId', measureId)
  //     console.log('title', title)
  //     console.log('description', description)
  //     console.log('user', Meteor.userId() + Meteor.user().emails)
  //     console.log('email', Meteor.user().emails)
  //     let measure = Measures.findOne({ '_id': measureId })
  //     console.log('measure', measure)
  //
  //     check(title, String)
  //     check(description, String)
  //     if (measure.properties.createdBy === Meteor.userId()) {
  //         Measures.update(measureId, {
  //             $set: {
  //                 updatedAt: new Date(),
  //                 'properties.title': title,
  //                 'properties.description': description
  //             }
  //         })
  //     }
  //     console.log('measure.update END', measureId)
  // },
  'user.update': function userUpdate(profile, newUsername) {
    var oldUsername = Meteor.user().emails[0].address; // console.log('oldUsername', oldUsername)
    // console.log('newUsername', newUsername)
    // console.log('test', oldUsername !== newUsername)

    if (oldUsername !== newUsername) {
      // console.log('GGGGGGGGGGGGGGGGGGGGGGOOOD')
      Accounts.addEmail(this.userId, newUsername);
      Accounts.removeEmail(this.userId, oldUsername); // Accounts.setUsername(Meteor.userId(), newUsername)
    }

    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $set: {
        updatedAt: new Date(),
        'profile.email': newUsername,
        'profile.name': profile.name // 'profile.city': profile.city,
        // 'profile.number': profile.number,
        // 'profile.centerCoordinates': profile.centerCoordinates,
        // 'profile.pdokv2': profile.pdokv2,
        // 'profile.pdokv2_query': profile.pdokv2_query

      }
    });
  },
  'user.updateHome': function userUpdateHome(coordinates) {
    console.log('--- update HOME marker coordinates: ', coordinates);
    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $set: {
        updatedAt: new Date(),
        'profile.centerCoordinates': coordinates
      }
    });
  },
  'error.gen': function errorGen(message) {
    console.log('ERROR server');
    var a = 1 / 0;
    console.log(a); // Meteor.call('error.gen')

    throw new Error('Meteorr SERVER test error: ' + new Date().getTime());
  },
  'log': function log(message) {
    console.log('HALLO2');
    console.log(message);
  },

  /**
   * upload base64 image method
   *
   * @param {*} file
   *
   * @param {string}     file.userid
   * @param {string}     file.type
   * @param {number}     file.size
   * @param {string}     file.name
   * @param {string}     file.extension - file extension
   * @param {string}     file.content   - base64 string
   */
  uploadImageFileBase64: function uploadImageFileBase64(base64Image, measureId) {
    console.log('uploadImageFileBase64 CALLEDd MVD'); // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    // console.log(base64regex.test('SomeStringObviouslyNotBase64Encoded...'))
    // console.log(base64regex.test('U29tZVN0cmluZ09idmlvdXNseU5vdEJhc2U2NEVuY29kZWQ='))

    var file = {
      content: base64Image,
      userid: Meteor.userId(),
      extension: 'jpg',
      measureid: measureId
    }; // console.log('content', base64Image.test(file.content))
    // console.log(file)
    // if (Meteor.userId()) {

    var date = new Date();
    var time = date.toLocaleDateString() + ' ' + date.toLocaleTimeString(); // Meteor.http.post(Meteor.settings.public.uploadImgUrl, { params: file },
    // HTTP.call('POST', 'http://217.21.192.155/beeapp/php/services/img.upload.php', { params: file },
    // HTTP.call('POST', 'https://cdnbij.deontwikkelfabriek.nl/php/services/img.upload.php', { params: file },

    HTTP.call('POST', Meteor.settings.public.uploadImgUrl, {
      params: file
    }, // HTTP.call('POST', 'http://ptsv2.com/t/beeapp/post', { params: file },
    Meteor.bindEnvironment(function (error, response, body) {
      // console.log(response)
      if (!error && response.statusCode === 200) {
        var content = JSON.parse(response.content);

        if (content.status === 200) {// console.log('uploadImageFileBase64: Image uploaded')
          // var id = Images.insert({
          //     measureId: measureId,
          //     userid: Meteor.userId(),
          //     // name: content.name,
          //     // originalName: file.name.substring(0, file.name.lastIndexOf('.')),
          //     // originalFile: file.name,
          //     file: content.file,
          //     ext: content.ext,
          //     original: '/images/original/' + content.userid + '/' + content.name + '.' + content.ext,
          //     medium: '/images/medium/' + content.userid + '/' + content.name + '.' + content.ext,
          //     thumb: '/images/thumb/' + content.userid + '/' + content.name + '.' + content.ext,
          //     square: '/images/square/' + content.userid + '/' + content.name + '.' + content.ext
          // })
          // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
          // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
          // let imagename = content.name + '.' + content.ext
          // Measures.update({ _id: measureId }, { $set: { 'properties.photo': imagename } })
          // console.log('imagename: ' + imagename)
        } else {
          console.log(content.status);
          console.log(_typeof(content.status));
          console.log('\x1b[31m%s\x1b[0m', response.content);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + Meteor.settings.public.uploadImgUrl + '] - ' + time);
      }
    }, function (e) {
      console.log('\x1b[31m%s\x1b[0m', e);
    })); // } else {
    //     return {
    //         status: 530,
    //         message: 'Not logged in'
    //     }
    // }
  },

  /**
   * upload base64 image method
   *
   * @param {*} file
   *
   * @param {string}     file.userid
   * @param {string}     file.type
   * @param {number}     file.size
   * @param {string}     file.name
   * @param {string}     file.extension - file extension
   * @param {string}     file.content   - base64 string
   */
  uploadImageFileBase64OLD: function uploadImageFileBase64OLD(base64Image, measureId) {
    console.log('uploadImageFileBase64 CALLEDd MVD'); // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    // console.log(base64regex.test('SomeStringObviouslyNotBase64Encoded...'))
    // console.log(base64regex.test('U29tZVN0cmluZ09idmlvdXNseU5vdEJhc2U2NEVuY29kZWQ='))

    var file = {
      content: base64Image,
      userid: Meteor.userId(),
      extension: 'jpg',
      measureid: measureId
    }; // console.log(base64regex.test(file.content))
    // console.log(file)

    console.log('---------------------V=1--------------'); // if (Meteor.userId()) {

    var date = new Date();
    var time = date.toLocaleDateString() + ' ' + date.toLocaleTimeString(); // Meteor.http.post(Meteor.settings.public.uploadImgUrl, { params: file },
    // HTTP.call('POST', 'http://217.21.192.155/beeapp/php/services/img.upload.php', { params: file },
    // HTTP.call('POST', 'https://cdnbij.deontwikkelfabriek.nl/php/services/img.upload.php', { params: file },

    HTTP.call('POST', Meteor.settings.public.uploadImgUrl, {
      params: file
    }, // HTTP.call('POST', 'http://ptsv2.com/t/beeapp/post', { params: file },
    Meteor.bindEnvironment(function (error, response, body) {
      console.log(response);

      if (!error && response.statusCode === 200) {
        var content = JSON.parse(response.content);

        if (content.status === 200) {
          var id = Images.insert({
            measureId: measureId,
            userid: Meteor.userId(),
            // name: content.name,
            // originalName: file.name.substring(0, file.name.lastIndexOf('.')),
            // originalFile: file.name,
            file: content.file,
            ext: content.ext,
            original: '/images/original/' + content.userid + '/' + content.name + '.' + content.ext,
            medium: '/images/medium/' + content.userid + '/' + content.name + '.' + content.ext,
            thumb: '/images/thumb/' + content.userid + '/' + content.name + '.' + content.ext,
            square: '/images/square/' + content.userid + '/' + content.name + '.' + content.ext
          });
          console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time); // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
          // let imagename = content.name + '.' + content.ext
          // Measures.update({ _id: measureId }, { $set: { 'properties.photo': imagename } })
          // console.log('imagename: ' + imagename)
        } else {
          console.log(content.status);
          console.log(_typeof(content.status));
          console.log('\x1b[31m%s\x1b[0m', response.content);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + Meteor.settings.public.uploadImgUrl + '] - ' + time);
      }
    }, function (e) {
      console.log('\x1b[31m%s\x1b[0m', e);
    })); // } else {
    //     return {
    //         status: 530,
    //         message: 'Not logged in'
    //     }
    // }
  }
});